@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    --bg-color: white;
    --text-color: black;
}


html.dark {
    --bg-color: #1a1a1a;
    --text-color: white;
}

body {
    background-color: var(--bg-color);
    color: var(--text-color);
}

.card {
    background: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

html.dark body {
    background: #040d19;
}

html.dark .card {
    background: #071426;
}

.ticker {
    display: inline-block;
    animation: ticker-scroll 10s linear infinite;

}

@keyframes ticker-scroll {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.carousel-item {
    @apply w-24 h-24 flex items-center justify-center bg-gray-200 dark:bg-gray-700 rounded-full shadow-lg;
}

.carousel-item img {
    @apply w-12 h-12;
}

.thin-tag {
    padding: 1px 8px !important; /* Adjust the padding to make it thinner */
    font-size: 0.75rem; /* Adjust the font size as needed */
    line-height: 1rem; /* Adjust the line height to keep the text centered */
}